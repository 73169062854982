<template>
  <div class="video-wrapper" v-if="itemForVideo">
    <div
      @click="closeTheVideoModal"
      class="close-button-wrapper"
    >
      <img
        class="play-button" src="@/assets/closebutton.png"
        style="width: 45px; cursor: pointer;"
      />
    </div>
    <video
      :controls="false"
      ref="video"
      :poster="require(`@/assets/${itemForVideo.img}`)"
      @click="clickOnVideo"
      @pause="videoStopped"
      @playing="videoPlaying"
      @canplay="canPlay"
      @ended="showPreviewForNextVideo"
      :autoplay="true"
      width="1280"
    >
      <source :src="require(`@/assets/${itemForVideo.videoLink}`)" type="video/mp4">
    </video>
    <div id="play-button" v-show="showButton && !showNextPreview" @click="playVideo">
      <img class="play-button" src="@/assets/play-button.png" alt="play-button"/>
    </div>
    <div id="next-video-preview" v-if="showNextPreview && currentItemForPreview">
      <div class="background-overlay"></div>
      <div class="next-card-wrapper">
        <h2>Nächster Film</h2>
        <div class="next-card">
          <img :src="require(`../assets/${currentItemForPreview.img}`)" alt=""/>
          <h2>{{ currentItemForPreview.h2 }}</h2>
          <h3>{{ currentItemForPreview.h3 }}</h3>
          <div class="play-button" @click="playNextVideo">
            <img src="@/assets/play-button.png"/>
            <p>Film starten</p>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div
        id="video-controls"
        ref="video-controls"
        class="controls"
        v-show="showControls"
      >
        <div class="time-info">
          <div v-if="currentPosition || currentPosition === 0">
            {{ currentPosition | secondsToTimeFormat }}
          </div>
          <div v-if="duration">{{ duration | secondsToTimeFormat }}</div>
        </div>
        <div class="video-seekbar seekbar" ref="seekbar" @click="calculateWhereIAm">
          <span class="progress" ref="progress-bar"></span>
        </div>
        <div class="description">
          <div>
            <h2>{{ itemForVideo.h2 }}</h2>
            <h3>{{ itemForVideo.h3 }}</h3>
          </div>
          <div style="display: flex;">
            <div class="back-to-overview" @click="closeTheVideoModal">
              <img class="play-button" src="@/assets/back-to-overview.png"/>
              <p>Zurück zur Übersicht</p>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// helper functions
const addZeroToNumber = (number) => {
  if (number === undefined) {
    return '00';
  }
  if (number < 10) {
    return `0${number}`;
  }
  return `${number}`;
};

const getPercentage = (currentTime, totalTime) => {
  const calculatePercentage = (currentTime / totalTime) * 100;
  return parseFloat(calculatePercentage.toString());
};

export default {
  name: 'Video',
  data: () => ({
    duration: null,
    currentPosition: null,
    showButton: true,
    showControls: false,
    videoIsPlaying: null,
    showNextPreview: false,
    itemForVideo: null,
    itemsForPreview: [
      {
        id: 1,
        h2: 'Schändliche ‚Schaufahrt‘ ins KZ',
        h3: 'Adam wird nach Kislau verschleppt',
        text: 'Als langjähriger badischer Innenminister hat sich Adam Remmele unerschrocken den Extremisten aller Lager entgegengestellt. Den badischen Nazis gilt er deshalb als ihr Hauptfeind. Gemeinsam mit sechs weiteren bekannten Sozialdemokraten wird Remmele am 16. Mai 1933 im Rahmen einer erniedrigenden ‚Schaufahrt‘ vom Karlsruher Bezirksgefängnis in das kurz zuvor errichtete KZ Kislau verschleppt.',
        videoLink: 'schaufahrt.mp4',
        img: 'preview-video01.jpg',
      },
      {
        id: 2,
        h2: 'Wortloses Widerstehen im KZ',
        h3: 'Kurt will Gedenken und bezahlt dafür',
        text: 'Im Frühjahr 1933 wird der Freiburger Kommunist Kurt Hilbig ins südbadische KZ Ankenbuck verschleppt. Als er dort im Juni desselben Jahres erfährt, dass die KPD-Politikerin Clara Zetkin im Exil gestorben ist, organisiert er eine Gedenkminute. Seine Aktion wird verraten – mit drastischen Folgen: Hilbig wird mit verschärftem Arrest belegt und von sadistischen KZ-Wärtern halbtot geprügelt.',
        videoLink: 'wortlos.mp4',
        img: 'preview-video02.jpg',
      },
      {
        id: 3,
        h2: 'Ein Kirchenmann als ‚Staatsfeind',
        h3: 'Franz predigt gegen das NS-Regime',
        text: 'In Plankstadt geraten die Nationalsozialisten immer wieder mit Mitgliedern der katholischen Gemeinde St. Nikolaus in Konflikt. Wegen einer regimekritischen Predigt wird der Priester Franz Stattelmann 1935 ins Konzentrationslager Kislau verschleppt. Nur dank der Fürsprache des Erzbischofs kommt er nach sechs Wochen wieder frei. Schon wenig später muss er für ein halbes Jahr ins Gefängnis.',
        videoLink: 'plankstadt.mp4',
        img: 'preview-video03.jpg',
      },
    ],
    currentItemForPreview: null,
  }),
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  created() {
    this.itemForVideo = this.item;
    this.setCurrentItemForPreview();
  },
  mounted() {
    this.playVideo();
    console.log('play video');
  },
  methods: {
    setCurrentItemForPreview() {
      this.currentItemForPreview = this.itemsForPreview.find(
        (item) => (this.itemForVideo.id % 3) + 1 === item.id,
      );
    },
    calculateWhereIAm(e) {
      const positionPercent = e.offsetX / this.$refs.seekbar.clientWidth;
      const currentPosition = positionPercent * this.duration;
      this.setProgressBar(currentPosition);
      this.$refs.video.currentTime = positionPercent * this.duration;
      setTimeout(() => {
        this.$refs.video.play();
      }, 300);
    },
    playVideo() {
      this.$refs.video.play();
    },
    videoPlaying() {
      this.showButton = false;
      this.showControls = false;
      console.warn('the video is playing');
      // set the class of the wrapper
      this.showNextPreview = false;
      this.videoIsPlaying = true;
    },
    videoStopped() {
      if (this.$refs.video?.currentTime) {
        this.currentPosition = this.$refs.video.currentTime;
      }
      console.log('after');
      this.showButton = true;
      this.showControls = true;
      console.warn('the video is stopped');
      this.videoIsPlaying = false;
      this.setProgressBar(this.currentPosition);
    },
    canPlay() {
      console.log('current time can play');
      this.duration = this.$refs.video.duration;
      if (this.$refs.video?.currentTime) {
        this.currentPosition = this.$refs.video.currentTime;
      }
      console.log('CT', this.$refs.video.currentTime);
      console.log('video can play', this.$refs.video.duration);
      console.log('duration set', this.duration);
    },
    clickOnVideo() {
      this.showNextPreview = false;
      if (this.videoIsPlaying) {
        this.$refs.video.pause();
      } else {
        this.$refs.video.play();
      }
    },
    closeTheVideoModal() {
      console.log('close the modal');
      this.$refs.video.pause();
      this.$refs.video.removeAttribute('src'); // empty source
      this.showNextPreview = false;
      this.$emit('close-modal');
      console.log('video stopped');
    },
    setProgressBar(currentPosition) {
      if (this.$refs['progress-bar']?.style) {
        const seekPercentage = getPercentage(currentPosition, this.duration);
        this.$refs['progress-bar'].style.width = `${seekPercentage}%`;
      }
    },
    showPreviewForNextVideo() {
      console.log('video finished');
      // hide the play button or just make the overlay!!!
      this.showNextPreview = true;
    },
    playNextVideo() {
      // change the item of the current item
      this.itemForVideo = this.currentItemForPreview;
      this.showControls = false;
      this.showNextPreview = false;
      this.$refs.video.load();
      this.$refs.video.play();
      this.setCurrentItemForPreview();
      this.$emit('move-to-next-video', this.itemForVideo.id);
    },
  },
  filters: {
    secondsToTimeFormat(value) {
      if (!value) return '00:00';
      const seconds = addZeroToNumber(parseInt(value % 60, 10));
      const minutes = addZeroToNumber(parseInt((value % 3600) / 60, 10));

      return `${minutes}:${seconds}`;
    },
  },
};
</script>

<style scoped lang="scss">
h2, h3 {
  font-size: 17px;
  line-height: 1.3;
  margin: 0;
  padding: 0;
}

h3 {
  font-size: 15px;
  font-weight: 400;
}

.parent-element {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-wrapper {
  position: relative;

  video {
    display: block;
  }

  #video-controls {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 16px;
    background-color: rgba(223, 237, 240, .9);

    .time-info {
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      padding-bottom: 4px
    }

    .seekbar {
      display: block;
      position: relative;
      width: 100%;
      height: 16px;
      margin-bottom: 8px;
      background-color: #9FC3D0;

      .progress {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: #7891A4;
        width: 0;
        height: 16px;
      }
    }

    .description {
      display: flex;
      justify-content: space-between;

      .back-to-overview {
        display: flex;
        align-items: center;
        color: #7891A4;

        img {
          width: 25px;
          height: 25px;
          margin-right: 10px;
        }

        p {
          font-size: 12px;
          line-height: 25px;
          margin: 0;
        }
      }
    }
  }

  .back-to-overview {
    cursor: pointer;
  }

  #play-button {
    position: absolute;
    z-index: 10000;
    top: calc(50% - 60px);
    left: calc(50% - 40px);

    img {
      width: 80px;
      height: 80px;
    }
  }
}

#next-video-preview {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../assets/poster.jpg');

  .background-overlay {
    background-color: rgba(0, 0, 0, .76);
    height: 100%;
    width: 100%;
  }

  .next-card-wrapper {
    > h2 {
      color: #fff;
      font-size: 30px;
      margin-bottom: 20px;
    }

    position: absolute;
    width: 400px;
    z-index: 20000;
    margin-left: auto;
    margin-right: auto;
    top: 70px;
    left: 0;
    right: 0;

    .next-card {
      background-color: #DFEDF0;
      padding-bottom: 20px;

      h2, h3 {
        font-size: 18px;
        color: #000;
      }

      h2 {
        margin: 10px 35px 0 35px;
      }

      h3 {
        margin: 0 35px 25px 35px;
      }

      > img {
        display: block;
        width: 100%;
      }

      .play-button {
        display: flex;
        align-items: center;
        color: #cf5153;

        img {
          width: 45px;
          height: 45px;
          margin-right: 15px;
          display: block;
          margin-left: 35px;
        }

        p {
          font-size: 18px;
          font-weight: 400;
          margin: 0;
        }
      }
    }
  }
}

.close-button-wrapper {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 5000;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .7s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
