import Vue from 'vue';
import VueCoreVideoPlayer from 'vue-core-video-player';
import Vue2TouchEvents from 'vue2-touch-events';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';

Vue.use(Vue2TouchEvents);

Vue.use(VueCoreVideoPlayer);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
