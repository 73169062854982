<template>
  <div class="main-element">
    <div class="card-wrapper" @touchstart="touchStart">
      <div
        v-for="(item, index) in items"
        class="card"
        :class="leftAndRightCardClass(index)"
        :key="'test-'+index"
        @click="moveElements(index)"
      >

        <img :src="require(`../assets/${item.img}`)" alt=""/>
        <div class="card-body">
          <h2>{{ item.h2 }}</h2>
          <h3>{{ item.h3 }}</h3>
          <p>{{ item.text }}</p>
        </div>
        <div class="play-button" @click="openVideo(item.id)">
          <img class="play-button" src="@/assets/play-button.png"/>
          <p>Film starten</p>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div class="modal-window" v-if="show">
        <Video
          :item="currentItem"
          @close-modal="closeTheModalWindow"
          @move-to-next-video="moveToNextVideo"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import Video from '@/views/video.vue';

export default {
  name: 'VideoScreen',
  components: { Video },
  data: () => ({
    toggleResize: false,
    show: false,
    videoPlayId: null,
    currentItem: null,
    items: [
      {
        id: 3,
        h2: 'Ein Kirchenmann als ‚Staatsfeind‘',
        h3: 'Franz predigt gegen das NS-Regime',
        text: 'In Plankstadt geraten Mitglieder der katholischen Gemeinde St. Nikolaus immer wieder mit den Nationalsozialisten in Konflikt. Nach einer regimekritischen Predigt wird der Priester Franz Stattelmann 1935 ins Konzentrationslager Kislau verschleppt. Nur dank der Fürsprache des Erzbischofs kommt er nach sechs Wochen wieder frei. Wenig später muss er für ein halbes Jahr ins Gefängnis.',
        videoLink: 'plankstadt.mp4',
        img: 'preview-video03.jpg',
      },
      {
        id: 1,
        h2: 'Schändliche ‚Schaufahrt‘ ins KZ',
        h3: 'Adam wird nach Kislau verschleppt',
        text: 'Als langjähriger badischer Innenminister hat Adam Remmele sich unerschrocken den Extremisten aller Lager entgegengestellt. Die badischen Nazis sehen deshalb in ihm ihren Hauptfeind. Gemeinsam mit sechs weiteren bekannten Sozialdemokraten wird Remmele am 16. Mai 1933 im Rahmen einer erniedrigenden ‚Schaufahrt‘ vom Karlsruher Bezirksgefängnis in das kurz zuvor errichtete KZ Kislau verschleppt.',
        videoLink: 'schaufahrt.mp4',
        img: 'preview-video01.jpg',
      },
      {
        id: 2,
        h2: 'Wortloses Widerstehen im KZ Ankenbuck',
        h3: 'Kurt will gedenken und bezahlt dafür',
        text: 'Im Frühjahr 1933 wird der Freiburger Kommunist Kurt Hilbig ins südbadische KZ Ankenbuck verschleppt. Als er dort erfährt, dass die KPD-Politikerin Clara Zetkin im Exil gestorben ist, organisiert er eine Gedenkminute. Seine Aktion wird verraten – mit drastischen Folgen: Hilbig wird mit verschärftem Arrest belegt und von sadistischen KZ-Wärtern halbtot geprügelt.',
        videoLink: 'wortlos.mp4',
        img: 'preview-video02.jpg',
      },
    ],
  }),
  methods: {
    touchStart(touchEvent) {
      if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
        return;
      }
      const posXStart = touchEvent.changedTouches[0].clientX;
      // eslint-disable-next-line no-shadow
      this.$el.addEventListener('touchend', (touchEvent) => this.touchEnd(touchEvent, posXStart), { once: true });
    },
    touchEnd(touchEvent, posXStart) {
      if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
        return;
      }
      const posXEnd = touchEvent.changedTouches[0].clientX;
      if (posXStart + 5 < posXEnd) {
        this.moveElements(0); // swipe right
      } else if (posXStart - 5 > posXEnd) {
        this.moveElements(2); // swipe left
      }
    },
    leftAndRightCardClass(index) {
      if (index === 0) {
        return 'resized left';
      }
      if (index === 2) {
        return 'resized right';
      }
      return 'middle';
    },
    openVideo(videoId) {
      console.log('item', this.items.find((item) => item.id === videoId));
      this.currentItem = { ...this.items.find((item) => item.id === videoId) };
      this.videoPlayId = videoId;
      this.show = !this.show;
    },
    moveElements(indexOfItems) {
      if (indexOfItems === 0) {
        const lastItem = this.items.pop();
        this.items.unshift(lastItem);
      }
      if (indexOfItems === 2) {
        const firstItem = { ...this.items[0] };
        this.items.push(firstItem);
        this.items.shift();
      }
      console.log('moved', indexOfItems);
    },
    closeTheModalWindow() {
      console.log('close event');
      this.show = false;
    },
    moveToNextVideo(newId) {
      const indexOfItem = this.items.findIndex((item) => item.id === newId);
      this.moveElements(indexOfItem);
    },
  },
};
</script>

<style scoped lang="scss">
.main-element {
  padding-top: 25px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-list-move {
  transition: transform 0.8s ease;
}

.card-wrapper {
  height: calc(100vh - 50px);
  width: 3000px;
  display: flex;
}

.card {
  background-color: #DFEDF0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  width: 600px;
  transition: all .2s ease-out;
  margin: 0 20px;

  &.middle {
    width: 800px;
  }

  &.resized.right {
    transform: scale(0.75, 0.75) translateX(-120px);
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    .play-button {
      margin-top: auto;
    }
  }

  &.resized.left {
    transform: scale(0.75, 0.75) translateX(120px);
  }

  img {
    width: 100%;
    display: block;
  }

  .play-button {
    margin-left: 30px;
    margin-bottom: 20px;
    display: flex;
    cursor: pointer;

    img {
      width: 45px;
      height: 45px;
      margin: 0;
      margin-right: 20px;
    }

    p {
      margin: 0;
      line-height: 45px;
      font-weight: 700;
      color: #cf5153;
    }
  }

  .card-body {
    padding: 20px 30px 0 30px;

    h2 {
      font-size: 28px;
      line-height: 32px;
      margin: 0;
    }

    h3 {
      font-size: 28px;
      line-height: 32px;
      margin-top: 0;
      font-weight: 400;
      margin-bottom: 10px;
    }

    p {
      font-size: 18px;
      margin: 0 0 15px 0;
    }
  }
}

.modal-window {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;

  video {
    width: 100%;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
